import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';

import { ConfirmComponent } from 'src/app/dialogs/confirm/confirm.component';

import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DashboardComponent implements OnInit, OnDestroy {

  private dashSub: Subscription = new Subscription();

  user: any = { name: '' };

  products = [];
  total = 0;

  constructor(
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.dashSub.add(
      this.userService.getUserObservable().subscribe(user => {
        this.user = user;
      })
    );

    this.dashSub.add(
      this.userService.getProductsObservable().subscribe(pro => {
        this.products = pro;
        this.total = 0;
        this.products.forEach(p => {
          if (p.selected === 1){
            this.total += p.price;
          }
        });
      })
    );
  }

  selectProduct(product) {
    product.selected = product.selected === 0 ? 1 : 0;
    this.total = 0;
    this.products.forEach(p => {
      if (p.selected === 1){
        this.total += p.price;
      }
    });
  }

  paynow() {
    this.userService.sendPayNotification();
    const dialogRef = this.dialog.open(ConfirmComponent,
      // tslint:disable-next-line: max-line-length
      {width: '450px', data: { title: 'Delete Question '} }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result !== '' && result !== undefined) {

      }
    });
  }

  ngOnDestroy() {
    this.dashSub.unsubscribe();
  }

  signout() {
    this.userService.logout();
  }

}

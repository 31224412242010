import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { InterceptorModule } from './interceptor/interceptor.module';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

import { CookieService } from 'ngx-cookie-service';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { Globals } from './services/globals';
import { AppComponent } from './app.component';

import { ConfirmComponent } from './dialogs/confirm/confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    ConfirmComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InterceptorModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    MatTabsModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,

    NgxUiLoaderModule
  ],
  entryComponents: [
    ConfirmComponent
  ],
  providers: [Globals, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { UserService } from 'src/app/services/user.service';
import { Globals } from '../services/globals';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  private adminSub: Subscription = new Subscription();

  filterString = '';
  order: any = {};
  orders: any[] = [];
  displayedColumns: string[] = ['trans_id', 'email', 'created_at'];
  dataSource;
 
  constructor(
    public dialog: MatDialog,
    private globals: Globals,
    private http: HttpClient,
    private userService: UserService
  ) { }

  ngOnInit() {

    this.adminSub.add(
      this.http.get(this.globals.api + '/orders' + this.globals.debug )
      .subscribe((data: any) => {
        this.orders = data;
        this.dataSource = new MatTableDataSource(this.orders);
        this.dataSource.filterPredicate = function (dataIn: any, filter: string): boolean {
          return dataIn.trans_id.toLowerCase().includes(filter);
        };

        }

      )
    );
  }

  openResponseDialog(responseIn) {
    console.log('response', responseIn);
    this.order = responseIn;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    this.adminSub.unsubscribe();
  }

  signout() {
    this.userService.logout();
  }
  
}

import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UserService } from 'src/app/services/user.service';
import { Globals } from '../../services/globals';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {

  private dashSub: Subscription = new Subscription();

  error = false;
  transId = '';
  codeForm: FormGroup;
  get fc() { return this.codeForm.controls; }

  @ViewChild('digit1', {static: false}) digit1Element: ElementRef;
  @ViewChild('digit2', {static: false}) digit2Element: ElementRef;
  @ViewChild('digit3', {static: false}) digit3Element: ElementRef;
  @ViewChild('digit4', {static: false}) digit4Element: ElementRef;
  @ViewChild('digit5', {static: false}) digit5Element: ElementRef;


  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private globals: Globals,
    private userService: UserService,
    private fb: FormBuilder,
    private http: HttpClient
    ) { }

  ngOnInit() {

    this.codeForm = this.fb.group({
      digit1: ['', [Validators.required, Validators.maxLength(1)]],
      digit2: ['', [Validators.required, Validators.maxLength(1)]],
      digit3: ['', [Validators.required, Validators.maxLength(1)]],
      digit4: ['', [Validators.required, Validators.maxLength(1)]],
      digit5: ['', [Validators.required, Validators.maxLength(1)]]
    });

    this.codeForm.get('digit1').valueChanges.pipe(
      filter((value: string) => value.length === 1))
      .subscribe(() => this.digit2Element.nativeElement.focus());

    this.codeForm.get('digit2').valueChanges.pipe(
      filter((value: string) => value.length === 1))
      .subscribe(() => this.digit3Element.nativeElement.focus());

    this.codeForm.get('digit3').valueChanges.pipe(
      filter((value: string) => value.length === 1))
      .subscribe(() => this.digit4Element.nativeElement.focus());

    this.codeForm.get('digit4').valueChanges.pipe(
      filter((value: string) => value.length === 1))
      .subscribe(() => this.digit5Element.nativeElement.focus());

    this.dashSub.add(
      this.userService.getOrderObservable().subscribe(order => {
        this.data = order;
      })
    );
  }

  ngOnDestroy() {
    this.dashSub.unsubscribe();
  }

  checkCode( event ) {
    event.preventDefault();
    const digits = this.codeForm.value;
    const codeIn = digits.digit1 + digits.digit2 + digits.digit3 + digits.digit4 + digits.digit5;
    const data = {code: codeIn, order_id: this.data.order_id};
    console.log(data);

    this.http.post(this.globals.api + '/codecheck' + this.globals.debug, data )
      .subscribe((success: any) => {
        console.log('success', success);
        if ( success === false){
          this.error = true;
        } else {
          this.transId = success;
          this.error = false;
        }

      });
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

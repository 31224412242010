
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  glyphLink = '';
  glyphParts = [{ x: '0px', y: '0px', img: '/assets/glyph_parts/gly10.png' }];

  products = [
    {name: 'Donation 5 USD', image: '/assets/Charitable-donations.jpg', price: 5, selected: 0},
    {name: 'Donation 10 USD', image: '/assets/Charitable-donations.jpg', price: 10, selected: 0},
    {name: 'Donation 50 USD', image: '/assets/Charitable-donations.jpg', price: 50, selected: 0}
  ];

  total = 0;

  signInError = false;
  trying = false;

  signinForm: FormGroup;
  get fc() { return this.signinForm.controls; }

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.signinForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.userService.getUsername()
      .subscribe(name => {
        switch (name) {
          case 'error':
            this.signInError = true;
            break;
          case 'noone':
            this.signInError = false;
            break;
          default:
            console.log('name', name);
            if (name === 'Martinadmin') {
              this.router.navigateByUrl('admin');
            } else {
              this.router.navigateByUrl('dashboard');
            }
        }
      });

    this.userService.getTrying()
        .subscribe(trying => {
        this.trying = trying;
      });

    this.userService.getSglyph()
      .subscribe(glyph => {
        if (glyph === '') { return; }
        this.glyphLink = 'smartglyph://#glyph=' + glyph;
        this.glyphParts = [];
        const res = glyph.split('');
        let index = 0;

        let yp = 45;
        let xp = 45;
        for (let y = 1; y < 6; y++) {
          for (let x = 1; x < 6; x++) {
            if (x === 5 && y === 5) {
              this.glyphParts.push({ x: xp + 'px', y: yp + 'px', img: '/assets/glyph_parts/gly10.png' });
            } else {
              this.glyphParts.push(
                { x: xp + 'px', y: yp + 'px', img: '/assets/glyph_parts/gly0' + res[index] + '.png' }
              );
            }
            xp = xp + 32;
            index++;
          }
          yp = yp + 32;
          xp = 45;
        }
      });

    this.userService.getSigninGlyph();
  }

  selectProduct(product) {
    product.selected = product.selected === 0 ? 1 : 0;
    this.total = 0;
    this.products.forEach(p => {
      if (p.selected === 1){
        this.total += p.price;
      }
    });
  }

  smartSignin() {
    this.userService.setProducts(this.products);
    this.userService.getSliUsed();
  }

}


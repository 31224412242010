
import { Injectable, NgModule } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap} from 'rxjs/operators';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class InterceptService  implements HttpInterceptor {

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });
    // request = request.clone({ setHeaders: { Authorization: `Bearer ${localStorage.getItem('MY_TOKEN')}`}});

    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // if (currentUser && currentUser.token) {
    //   request = request.clone({ setHeaders: { Authorization: `${currentUser.token}` }});
    // }

    // console.log('----request----');
    // console.log(request);
    //  console.log('--- end of request---');
    return next.handle(request).pipe(
      catchError((err, caught: Observable<HttpEvent<any>>) => {
        if (err instanceof HttpErrorResponse && err.status == 401) {
          this.userService.logout();
          return of(err as any);
        }
        console.log('---- KMJ response error ----');
        console.error('status code:', err.status);
        console.error(err.message);
        throw err;
      })
    );
  }
}

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true }
  ]
})
export class InterceptorModule { }

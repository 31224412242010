import { Injectable } from '@angular/core';

@Injectable()
// development
// export class Globals {
//  api = '/api';
//  debug = '?XDEBUG_SESSION_START=netbeans-xdebug';
// }


// production single server
export class Globals {
  api = '/api';
  debug = '?XDEBUG_SESSION_START=netbeans-xdebug';
}




import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

import { ShopComponent } from './shop/shop.component';
import { SigninComponent } from './signin/signin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { MissingPageComponent } from './missing-page/missing-page.component';


const routes: Routes = [
  { path: 'shop', component: ShopComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },

  { path: '', redirectTo: '/signin', pathMatch: 'full' },
  { path: '**', component: MissingPageComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  ShopComponent,
  SigninComponent,
  DashboardComponent,
  AdminComponent,
  MissingPageComponent
];

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';
import { Globals } from '../services/globals';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private username$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'noone'
  );
  private trying$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private sglyph$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private user$ = new BehaviorSubject({});

  private products$ = new BehaviorSubject([]);
  private order$ = new BehaviorSubject({});

  glyph = '';

  constructor(
    private globals: Globals,
    private cookieService: CookieService,
    private http: HttpClient,
    private router: Router,
    private uiLoader: NgxUiLoaderService
  ) {}

  getTrying() {
    return this.trying$.asObservable();
  }

  getUsername() {
    return this.username$.asObservable();
  }

  setProducts(products) {
    this.products$.next(products);
  }
  getProductsObservable() {
    return this.products$.asObservable();
  }

  loadUser() {
    const jwt = this.cookieService.get('jwt');
    if (jwt !== '') {
      this.http.get(this.globals.api + '/user' + this.globals.debug)
        .subscribe((userObject: any) => {
          if (userObject !== null) {
            userObject.owner = userObject.owner * 1;
            this.user$.next(userObject);
            this.username$.next(userObject.name);
          }
        });
    }
  }
  getUserObservable() {
    return this.user$.asObservable();
  }

  logout() {
    const temp = this.cookieService.getAll();

    this.uiLoader.stop();
    this.cookieService.delete('jwt');
    this.username$.next('noone');
    this.router.navigateByUrl('/signin');
  }

  isLoggedIn() {
    const cookieExists: boolean = this.cookieService.check('jwt');
    if (cookieExists) {
      return true;
    }
    // if (moment().isBefore(this.getExpiration())) {
    // const expiresIn = localStorage.getItem('expires_in');
    // const expiresAt = moment().add(expiresIn,'second');
    // localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()) );
    //   return true;
    // }
    return false;
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  // SLI
  getSigninGlyph() {
    this.http.get(this.globals.api + '/global/user/glyph' + this.globals.debug)
      .subscribe((data: any) => {
        this.glyph = data.glyph;
        this.sglyph$.next(data.glyph);
      });
  }
  getSglyph() {
    return this.sglyph$.asObservable();
  }

  getSliUsed() {
    this.trying$.next(true);
    this.http.get(this.globals.api + '/global/user/glyphused/' + this.glyph + this.globals.debug )
      .subscribe((data: any) => {
        if (data.user === false) {
          this.trying$.next(false);
          return false;
        }
        this.username$.next(data.firstname);
        this.cookieService.set('jwt', data.jwt);
        this.user$.next(data);
        this.trying$.next(false);
      });
  }

  sendPayNotification() {
    const data = this.products$.value;
    this.http.post(this.globals.api + '/paynote' + this.globals.debug, data )
      .subscribe((order: any) => {
        this.order$.next(order);
      });
  }

  getOrderObservable() {
    return this.order$.asObservable();
  }

}
